<template>
    <div class="telephone">
        <el-dialog v-model="dialogVisible" @close="close" :lock-scroll="true" width="700px">
            <template v-slot:title>
                <div class="flex_c">
                    <p class="fs_18 bold">记录详情</p>
                </div>
            </template>
            <div class="content">
                <div class="flex user-info">
                    <el-avatar :src="user.avatar" :size="100" class="avatar" alt="头像">
                        <img :src="
								user.gender == '男'
									? $store.state.nanAvatar
									: $store.state.nvAvatar
							" />
                    </el-avatar>
                    <div class="flex_1">
                        <div class="fs_18 bold color_000 mr_10">{{ user.realname || '职路用户' }}</div>
                        <div class="flex_c mt_15 use_tip color_333">
                            <span class="label color_999 mr_10">基本信息：</span>
                            <span class="line" v-if="user.gender" v-text="user.gender"></span>
                            <span class="line" v-if="user.age" v-text="user.age"></span>
                            <span class="line" v-if="user.current_city" v-text="user.current_city || ''"></span>
                        </div>
                        <div class="mt_10 fs_14 color_333 use_tip">
                            <span class="label color_999 mr_10">意向职位：</span>
                            <span class="line" v-text="user.job || '无'"></span>
                        </div>
                    </div>
                </div>
                <el-table :data="list" border height="202px">
                    <el-table-column prop="create_time" width="160" label="时间"></el-table-column>
                    <el-table-column prop="member_name" width="100" label="操作者"></el-table-column>
                    <el-table-column prop="status" width="80" label="状态"></el-table-column>
                    <el-table-column prop="remark" label="备注"></el-table-column>
                </el-table>
                <div class="flex mt_10">
                    <el-select v-model="form.status" placeholder="选择状态" class="form-item mr_10">
                        <el-option v-for="(item, index) in statusOption" :key="index" :value="item.value" :label="item.text">
                        </el-option>
                    </el-select>
                    <el-input class="flex_1 fs_14 form-item" v-model.trim="form.remark" placeholder="添加备注..."></el-input>
                </div>
                <div class="flex_cen">
                    <el-button type="primary" size="medium" :disabled="!form.status" :loading="loading" @click="submit">确 定</el-button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
// 用户标记
export default {
	name: "userMarker",
	data() {
		return {
			loading: false,
			data: {},
			id: 0,
			index: 0,
			dialogVisible: false,
			statusOption: [
				{ value: 'communication', text: '已沟通' },
				{ value: 'appropriate', text: '合适' },
				{ value: 'inappropriate', text: '不合适' },
				{ value: 'wait', text: '待定中' }
			],
			user: {},
			form: {
				status: '',
				remark: ''
			},
			list: []
		}
	},

	methods: {
		init() {
			this.$axios({
				url: `/ent/v3/talent/job_log/${this.id}`,
				method: 'GET'
			}).then(res => {
				if (res.code === 200) {
					this.list = res.data
				}
			})
		},
		close() {
			this.form.status = ''
			this.form.remark = ''
		},
		submit() {
			this.loading = true
			this.$axios({
				url: `/ent/v3/talent/job_log/${this.id}`,
				method: 'POST',
				shake: true,
				data: this.form
			}).then(res => {
				if (res.code === 200) {
					let status_text = this.statusOption.reduce((pre, cur) => {
						if (cur.value == this.form.status) {
							return pre.concat(cur.text)
						}
						return pre
					}, [])
					this.$parent.list[this.index].log_status = this.form.status
					this.$parent.list[this.index].log_status_txt = status_text.join('')
					this.$message.success(res.msg)
					this.dialogVisible = false
					this.form.status = ''
					this.form.remark = ''
				}
				this.loading = false
			})
		}
	}
}
</script>

<style lang="less" scoped>
.color_5AB0FF {
    color: #5ab0ff;
}
/deep/.el-dialog__header {
    padding: 20px;
}
/deep/.el-dialog__body {
    padding: 0;
}
.content {
    border-top: 1px solid #ddd;
    height: 536px;
    padding: 20px 25px;
    overflow-y: scroll;
    .user-info {
        margin-bottom: 25px;
        .avatar {
            width: 100px;
            height: 100px;
            margin-right: 20px;
        }
        .auth-tip {
            width: inherit;
            height: 18px;
            margin-right: 18px;
        }
        .btn {
            width: 86px;
            height: 22px;
            border-radius: 2px;
            .icon {
                width: 14px;
                height: 14px;
                margin-right: 5px;
            }
        }
        .info-tip {
            padding: 5px 12px;
            border-radius: 20px;
            background-color: #ededed;
            font-size: 14px;
            color: #666666;
            margin-right: 10px;
        }
    }
    /deep/.el-table thead tr th {
        height: 50px;
        background: #f8f8f8 !important;
        border: none;
        /deep/.cell {
            font-weight: bold;
        }
    }
    /deep/.el-table td,
    .el-table th.is-leaf,
    /deep/.el-table--border,
    .el-table--group {
        border-color: #f8f8f8;
    }
    /deep/.el-table__header,
    /deep/.el-table--border::after,
    .el-table--group::after,
    .el-table::before {
        background: #f8f8f8;
    }
    /deep/.cell {
        font-size: 14px;
        color: #000;
        text-align: center;
    }
    /deep/.el-table--enable-row-transition .el-table__body td {
        height: 50px;
    }

    /deep/.form-item {
        .el-input__inner {
            border: none;
            height: 50px;
            background: #f9f9f9;
            font-size: 14px;
            color: #000;
        }
    }
    .el-button {
        width: 200px;
        height: 50px;
        font-size: 18px;
        position: relative;
        z-index: 9;
        margin: 30px auto 0;
    }
}
</style>
