<template>
    <div class="contain" v-infinite-scroll="load" :infinite-scroll-disabled="finish" :infinite-scroll-distance="50">
        <searchBar ref="search_bar" placeholder="搜索职位" @search="search"></searchBar>
        <div class="cate">
            <div class="from">
                <div class="flex_c item">
                    <label class="label fs_14 color_999">所在地区：</label>
                    <el-radio-group v-model="filter.city" @change="initialization" class="flex_1">
                        <el-radio-button :label="0" class="pointer">
                            不限
                        </el-radio-button>
                        <el-radio-button v-for="(item, index) in city" :key="index" :label="item" class="pointer">
                            {{ item }}
                        </el-radio-button>
                    </el-radio-group>
                </div>
                <div class="flex_c item top_link">
                    <label class="label fs_14 color_999">学历要求：</label>
                    <el-checkbox-button label="不限" v-model="unlimited.education" :indeterminate="unlimited.education" @change="unlimitedChange('education')" class="mr_15"></el-checkbox-button>
                    <el-checkbox-group v-model="filter.education" @change="checkboxChange('education')" class="flex_c">
                        <template v-for="(item, index) in education" :key="index">
                            <el-checkbox v-if="item.value != 0" :label="item.value">
                                {{ item.label }}
                            </el-checkbox>
                        </template>
                    </el-checkbox-group>
                </div>
                <div class="flex_c item top_link">
                    <label class="label fs_14 color_999">工作经验：</label>
                    <el-radio-group v-model="filter.work_exp" @change="initialization" class="flex_1">
                        <el-radio-button v-for="(item, index) in work_exp" :key="index" :label="item.value" class="pointer">
                            {{ item.label }}
                        </el-radio-button>
                    </el-radio-group>
                </div>
                <div class="flex_c item top_link">
                    <label class="label fs_14 color_999 ">年龄区间：</label>
                    <div class="flex_1 flex_c">
                        <el-radio-group v-model="clearAge" @change="ageUnlimited">
                            <el-radio-button :label="0" class="pointer">
                                不限
                            </el-radio-button>
                        </el-radio-group>
                        <el-select v-model="filter.age_min" placeholder="最小年龄" @change="changeAge">
                            <el-option v-for="(item, index) in age_min" :key="index" :label="item.label" :value="item.value" :disabled="item.disabled"></el-option>
                        </el-select>
                        <span class="padding_10 info_color">-</span>
                        <el-select v-model="filter.age_max" placeholder="最大年龄" @change="changeAge">
                            <el-option v-for="(item, index) in age_max" :key="index" :label="item.label" :value="item.value" :disabled="item.disabled"></el-option>
                        </el-select>
                    </div>
                </div>
                <div class="flex_c item top_link">
                    <label class="label fs_14 color_999">性别：</label>
                    <el-radio-group v-model="filter.gender" @change="initialization">
                        <el-radio-button v-for="(item, index) in gender" :key="index" :label="item.value" class="pointer">
                            {{ item.label }}
                        </el-radio-button>
                    </el-radio-group>
                </div>
            </div>
            <div class="flex_start item selected">
                <label class="label fs_14 color_999 mr_15">已选：</label>
                <div class="">
                    <el-tag type="info" effect="plain" class="fs_14 mr_15 mb_10 btn" size="medium" v-show="filter.keyword">
                        搜索关键词：{{ filter.keyword }}
                    </el-tag>
                    <template v-for="(item, index) in selected" :key="index">
                        <template v-if="index != 'is_filter'">
                            <el-tag type="info" effect="plain" class="fs_14 mr_15 btn mb_10" size="medium" closable v-for="(val, key) in item" :key="key" @close="closeLabel(key, index)">
                                {{ val }}
                            </el-tag>
                        </template>
                    </template>
                    <el-tag type="text" class="fs_14 color btn closeAll" v-show="filter.keyword || selected.is_filter" @click="clearFilter">
                        一键清空
                    </el-tag>
                </div>
            </div>
        </div>
        <div class="tab flex bgf">
            <div class="left flex fs_16 color_999">
                <p class="item btn" :class="{ in: tab == 1 }" @click="changeTab(1)">
                    流动人才库
                </p>
                <p class="item btn" :class="{ in: tab == 2 }" @click="changeTab(2)">
                    已查看人才
                </p>
            </div>
            <div class="right flex fs_14 color_666">
                <p class="item btn" :class="{ in: order == 'resign' }" @click="changeSubtab('resign')">
                    最近离职
                </p>
                <p class="item btn" :class="{ in: order == 'stability' }" @click="changeSubtab('stability')">
                    稳定性高
                </p>
                <p class="item btn" :class="{ in: order == 'education' }" @click="changeSubtab('education')">
                    学历最高
                </p>
                <p class="reload-btn color_333 fs_14 btn flex_cen" @click="refresh">
                    <span class="el-icon-refresh fs_18 mr_5 color" :class="{move: refreshMove}"></span>
                    <span>刷新列表</span>
                </p>
            </div>
        </div>
        <!-- <div v-show="tab == 1"> -->
            <div class="list" v-if="list.length != 0">
                <img src="@/assets/imgs/group.png" v-if="tab==1" class="group-btn btn" @click="authPath([], handleGroupView)" alt="">
                <div class="item flex bottom_link" v-for="(item, index) in list" :key="index" @click="detail(item.id, index, item.view_status || item.user_invite_exists ? 'detail' : 'dialog')" :class="{ pointer: item.view_status == 1, unselect: item.view_status == 0 }">
                    <p class="status_icon flex_cen" v-if="item.view_status && tab == 1">
                        <span>已查看</span>
                    </p>
                    <p class="status_icon flex_cen in" v-if="item.protect_status && tab == 2">
                        <span>已入职保护</span>
                    </p>
                    <el-avatar :src="item.avatar" :size="80" class="avatar" alt="头像">
                        <img :src="item.gender == '男' ? $store.state.nanAvatar : $store.state.nvAvatar" />
                    </el-avatar>
                    <div class="flex_1 flex_l">
                        <div class="userinfo">
                            <div class="color_333 flex_c">
                                <p class="bold fs_16">{{ item.realname || '职路用户' }}</p>
                                <p class="color fs_12 flex_cen user-tag" v-if="item.view_status" @click.stop="changeUserMarker(item, index)">
                                    <span v-if="item.job_status">{{ item.log_status_txt || '已入职' }}</span>
                                    <span v-else-if="item.user_invite_exists">{{ item.log_status_txt || '已邀请' }}</span>
                                    <span v-else>{{ item.log_status_txt || '已查看' }}</span>
                                    <i class="el-icon-sort"></i>
                                </p>
                            </div>
                            <p class="flex_c fs_14 color_333 use_tip mt_10">
                                <span class="label">基本信息：</span>
                                <span class="line" v-if="item.current_city" v-text="item.current_city || ''"></span>
                                <span class="line" v-if="item.gender" v-text="item.gender"></span>
                                <span class="line" v-if="item.age" v-text="item.age"></span>
                                <span class="line" v-if="item.work_exp" v-text="item.work_exp"></span>
                                <span class="line" v-if="item.education" v-text="item.education"></span>
                            </p>
                            <p class="flex_c fs_14 color_333 use_tip mt_5" v-if="item.labels.length > 0">
                                <span class="label">求职关注点：</span>
                                <span class="line" v-for="(item, index) in item.labels" :key="index">
                                    {{ item }}
                                </span>
                            </p>
                            <p class="flex_start fs_14 color_333 use_tip mt_5">
                                <span class="label">最近工作经历：</span>
                                <template v-if="item.last_work_exp">
                                    <div class="flex_c one" style="width:80%">
                                        <span class="line color_5AB0FF one" style="display:inline-block;max-width:220px;" v-if="item.last_work_exp.company_id > 0" @click.stop="authPath([item.last_work_exp], showCompany)">
                                            {{ item.last_work_exp.company_name }}
                                        </span>
                                        <span class="line one" style="display:inline-block;max-width:220px" v-else>
                                            {{ item.last_work_exp.company_name }}
                                        </span>
                                        <span class="line">{{ item.last_work_exp.job_name }}</span>
                                        <span class="line">
                                            {{ item.last_work_exp.start_time }}至{{
                                                item.last_work_exp.end_time
                                            }}
                                        </span>
                                        <span class="line" v-if="item.last_work_exp.work_year">在职{{ item.last_work_exp.work_year }}</span>
                                    </div>
                                </template>
                                <template v-else>
                                    <span>暂无记录</span>
                                </template>
                            </p>
                        </div>
                        <div class="flex_1 flex">
                            <div class="job center">
                                <p class="fs_18 bold" v-text="item.job || '无'"></p>
                                <p class="fs_14 color_999 mt_5">意向职位</p>
                            </div>
                            <div class="mobile center">
                                <p class="fs_18 bold">{{ item.mobile }}</p>
                                <template v-if="item.user_invite_exists">
                                    <div class="btn flex_cen dis" @click.stop="authPath([item.id], inviteDetail)">
                                        <img src="@/assets/imgs/icon/dis-msg-icon.png" class="icon" />
                                        <p class="fs_14">已邀请</p>
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="btn flex_cen msg" v-if="item.view_status" @click.stop="authPath([item.id], detail)">
                                        <img src="@/assets/imgs/icon/msg-icon.png" class="icon" />
                                        <p class="fs_14">邀请面试</p>
                                    </div>
                                    <div class="btn flex_cen tel" v-else @click.stop="authPath([item.id, index], detail)">
                                        <img src="@/assets/imgs/icon/tel-icon.png" class="icon" />
                                        <p class="fs_14">电话沟通</p>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>

                <p class="loading_" style="background:#F6F8FC" v-if="!finish">
                    <i class="el-icon-loading fs_20"></i> 加载中，请稍后...
                </p>
            </div>
            <empty :finish="finish" :length="list.length" v-else></empty>
            <p class="loading_" v-if="list.length > 0 && finish && tab == 1">
                还没有找到心仪的人才？
                <span class="color btn" @click.stop="scrollTop">
                    试试添加筛选条件或直接搜索吧！
                </span>
            </p>
        <!-- </div> -->

        <!-- 电话联系 -->
        <telephone ref="telephone"></telephone>
        <!-- 面试邀请-基本信息 -->
        <resumeInfo ref="resumeInfo"></resumeInfo>
        <!-- 面试邀请 -->
        <invited ref="invited"></invited>
        <!-- 面试详情 -->
        <invited-detail ref="inviteDetail"></invited-detail>
        <!-- 公司详情 -->
        <company-info ref="companyInfo"></company-info>
        <!-- 用户标记 -->
        <user-marker ref="userMarker"></user-marker>
        <!-- 批量查看确认框 -->
        <telephone-group ref="telephoneGroup" @callback="groupView_callback"></telephone-group>
        <!-- 前20个批量查看列表 -->
        <group-views ref="groupViews"></group-views>
        <el-backtop :bottom="100"></el-backtop>
    </div>
</template>

<script>
import invited from "./dialog/invited.vue"
import invitedDetail from "./dialog/invite-detail.vue"
import telephone from "./dialog/telephone.vue"
import resumeInfo from "./dialog/resume-info.vue"
import companyInfo from "./dialog/company-info.vue"
import userMarker from "./dialog/user-marker.vue"
import groupViews from "./dialog/group-views.vue"
import telephoneGroup from './dialog/telephone-group.vue'
import { getEducationSelect, gender, getGender, work_exp, education_select, getExp, getAge, two2one } from "@/common/base.js"
export default {
	components: { telephone, resumeInfo, invited, invitedDetail, companyInfo, userMarker, groupViews, telephoneGroup },
	name: "Hall",
	data() {
		return {
			refreshMove: false,
			name: "hall",
			city: [],
			age_min: getAge(),
			age_max: getAge(),
			education: education_select, //学历数组
			gender, //性别数组
			work_exp,
			//控制多选不限
			unlimited: {
				education: true,
				experience: true
			},
			clearAge: 0, // 年龄不限
			select_label: [],
			//提交参数
			tab: 1,
			order: "resign", //排序：stability=稳定性高,education=学历高,resign=最近离职
			page: 1, //页码
			limit: 20,
			page_limit: 10, //必须大于1
			filter: {
				keyword: "",
				//学历
				education: [],
				//工作经历
				work_exp: 0,
				//性别
				gender: 0,
				city: 0,
				age_min: "",
				age_max: ""
			},
			finish: false,
			list: [],
			select_id: null
		}
	},
	created() {
		this.filter.keyword = this.$route.query.keywords || ""
		this.getCity()
		// this.init()
	},
	mounted() {
		this.$refs.search_bar.keywords = this.filter.keyword
	},
	computed: {
		selected() {
			let city = !this.filter.city ? [] : [ this.filter.city ]
			let gd = this.filter.gender == 0 ? [] : getGender([ this.filter.gender ])

			var edc = this.unlimited.education
				? []
				: getEducationSelect(this.filter.education) //学历
			var exp = this.filter.work_exp == 0 ? [] : getExp([ this.filter.work_exp ])
			let tmp_age = this.ageAttach(this.filter.age_min, this.filter.age_max)
			return {
				city,
				edc,
				exp,
				age: tmp_age,
				gender: gd,
				is_filter:
                    city.length > 0 ||
                    edc.length > 0 ||
                    exp.length > 0 ||
                    tmp_age.length > 0 ||
                    gd.length > 0
			}
			// return [].concat(city, edc, exp, tmp_age, gd)
		}
	},
	methods: {
		//变更邀请面试状态
		childInit() {
			for (var item of this.list) {
				if (item.id == this.select_id) {
					item.user_invite_exists = true
					item.log_status = 'companyInvite'
					item.log_status_txt = '已邀请'
					this.select_id = null
					break
				}
			}
		},
		refresh() {
			this.refreshMove = true
			this.initialization()
		},
		initialization() {
			this.page = 1
			this.list = []
			this.finish = false
			this.init()
		},
		getCity() {
			this.$axios({
				url: "/ent/v3/talent/city_list",
				method: "GET",
				hideLoading: true
			}).then(res => {
				if (res.code === 200) {
					this.city = res.data.list
					// this.filter.city = this.city[0]
					this.initialization()
				}
			})
		},
		init() {
			let url = this.tab == 1 ? "/ent/v3/talent" : "/ent/v3/talent/read_list"
			this.$axios({
				url,
				method: "GET",
				data: {
					page: this.page,
					limit: this.limit,
					order: this.order,
					...two2one(this.filter, "filter")
				},
				hideLoading: true,
				_cancel: true
			}).then(res => {
				if (res.code === 200) {
					this.list = this.list.concat(res.data.list)
					this.list = this.arrFilter(this.list)
					if(this.refreshMove) {
						setTimeout(()=>{
							this.refreshMove = false
						}, 800)
					}
					this.finish =
                        this.page >= this.page_limit || this.list.length >= res.data.total
				} else {
					this.finish = true
				}
			})
		},
		detail(id, index, type = "dialog") {
			this.select_id = id
			this.$axios({
				url: `/ent/v3/talent/view_user/${id}`,
				method: "GET",
				hideLoading: true
			}).then(res => {
				if (res.code === 200) {
					if (type == "dialog") {
						if (res.data.view_status == 0) {
							this.$refs.telephone.index = index
							this.handleDialog("telephone", res.data, id)
						} else {
							this.handleDialog("invited", {}, id)
						}
					} else if (res.data.view_status == 1) {
						this.$refs.resumeInfo.job = this.list[index].job
						this.handleDialog("resumeInfo", res.data, id)
					}
				}
			})
		},
		// 面试邀请详情
		inviteDetail(id) {
			this.$axios({
				url: `/ent/v3/talent/invite/show/${id}?type=user`,
				method: "GET",
				hideLoading: true
			}).then(res => {
				if (res.code === 200) {
					this.$refs.inviteDetail.form = res.data
					this.$refs.inviteDetail.dialogVisible = true
				}
			})
		},
		// 批量查看
		handleGroupView() {
			this.$refs.telephoneGroup.dialogVisible = true
		},
		groupView_callback() {
			this.$refs.groupViews.getList({
				page: 1,
				limit: 20,
				order: this.order,
				...two2one(this.filter, "filter")
			})
		},
		//滚动到底触发
		load() {
			this.page++
			if (this.page > this.page_limit) this.finish = true
			else this.init()
		},
		//搜索回调
		search(v) {
			this.filter.keyword = v
			this.initialization()
		},
		unlimitedChange(obj) {
			if (obj != "experience") this.filter[obj] = []
			else this.filter.work_exp = []
			this.unlimited[obj] = true
			this.initialization()
		},
		checkboxChange() {
			if(this.filter.education.length !==0) {
				this.unlimited.education = false
			}else {
				this.unlimited.education = true
			}

			this.initialization()
		},
		/* 删除指定筛选项 */
		closeLabel(index, type) {
			// { city, edc, exp, age: tmp_age, gender: gd }
			switch (type) {
			case "city":
				this.filter.city = ""
				break
			case "edc":
				this.filter.education.splice(index, 1)
				if (this.filter.education <= 0) this.unlimited.education = true
				break
			case "age":
				this.filter.age_min = this.filter.age_max = ""
				break
			case "exp":
				this.filter.work_exp = 0
				// this.filter.work_exp.splice(index, 1)
				// if (this.filter.work_exp <= 0) this.unlimited.experience = true
				break
			case "gender":
				this.filter.gender = 0
				break
			default:
				break
			}
			this.initialization()
		},
		/* 清空筛选项 */
		clearFilter() {
			//清空学历要求,清空工作经历
			this.unlimited.education = this.unlimited.experience = true

			this.filter = {
				keyword: "", //清空搜索关键字
				education: [], //清空学历要求
				// work_exp: [], //清空工作经历
				work_exp: 0, //重置工作经历
				gender: 0, //重置性别为不限
				city: 0,
				age_min: "",
				age_max: ""
			}
			this.clearAge = 0
			//清空搜索框内容
			this.$refs.search_bar.keywords = "" //清空插槽内搜索关键字
			//还原年龄选择
			this.age_min[0].disabled = this.age_max[0].disabled = false
			if (this.$route.query.keywords) {
				this.$router.replace("/hall")
			}
			this.initialization()
		},
		//年龄辅助函数
		ageAttach(min, max, type) {
			let tmp = type == null ? [] : false
			if (min === 0 && max === 0 || min === "" && max === "") {
				return tmp
			}
			if (min !== "" && max !== "")
				if (type == null) {
					tmp = [
						(min > 0 ? min + "岁" : "不限") +
                        "-" +
                        (max > 0 ? max + "岁" : "不限")
					]
				} else tmp = true

			return tmp
		},
		// 年龄不限
		ageUnlimited() {
			this.filter.age_min = ''
			this.filter.age_max = ''
			this.initialization()
		},
		changeAge() {
			this.clearAge = ''
			//判断最大值是否小于最小值 true则交换
			if (
				this.filter.age_min !== "" &&
                this.filter.age_max !== "" &&
                this.filter.age_max < this.filter.age_min
			) {
				var tmp = this.filter.age_min
				this.filter.age_min = this.filter.age_max
				this.filter.age_max = tmp
			}
			this.age_max[0].disabled = this.filter.age_min === 0
			if (this.age_max[0].disabled && this.filter.age_max === 0) {
				this.filter.age_max = ""
			}
			this.ageAttach(this.filter.age_min, this.filter.age_max, 1) &&
                this.initialization()
		},
		changeTab(v) {
			this.tab = v
			this.initialization()
		},
		changeSubtab(v) {
			this.order = this.order != v ? v : null
			this.initialization()
		},
		handleDialog(dialog, data, id) {
			this.$refs[dialog].id = id //用户ID
			if (dialog == "invited") {
				this.$refs[dialog].init()
			} else {
				this.$refs[dialog].data = data
			}
			this.$refs[dialog].dialogVisible = true
		},
		changeUserMarker(item, index) {
			this.$refs.userMarker.user = item
			this.$refs.userMarker.index = index
			this.$refs.userMarker.id = item.id
			this.$refs.userMarker.init()
			this.$refs.userMarker.dialogVisible = true
		},
		showCompany(item) {
			this.$refs.companyInfo.id = item.company_id
			this.$refs.companyInfo.init()
			this.$refs.companyInfo.dialogVisible = true
		},
		scrollTop() {
			document.querySelector("html").scrollTop = 0
		}
	}
}
</script>

<style lang="less" scoped>
@keyframes duangduang {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.color_5AB0FF {
    color: #5ab0ff;
}
.unselect {
    user-select: none;
}
.flex_l {
    display: flex;
}
.cate {
    .from {
        padding: 0 30px;
        background: #fff;
        .item {
            min-height: 50px;
            .label {
                line-height: 34px;
                width: 70px;
            }
            /deep/.el-input__inner {
                width: 130px;
            }
        }
    }
    .selected {
        padding: 14px 30px;
        // height: 60px;
        background: #fbfbfb;
        .label {
            width: 70px;
            height: 28px;
            line-height: 26px;
        }
    }
    .closeAll {
        border-bottom: 1px solid #1890ff !important;
        &.el-tag {
            background-color: transparent;
            border: none;
            border-radius: 0;
        }
    }
    /deep/.el-checkbox-button__inner,
    /deep/.el-radio-button__inner {
        border: none;
        font-size: 14px;
    }
    /deep/.el-checkbox-button.is-checked .el-checkbox-button__inner,
    /deep/.el-radio-button__orig-radio:checked + .el-radio-button__inner {
        background: #fff;
        background-color: #fff;
        color: #1890ff;
        border: none;
        box-shadow: none;
    }
    /deep/.el-checkbox__input.is-checked .el-checkbox__inner,
    .el-checkbox__input.is-indeterminate .el-checkbox__inner {
        background-color: #fff;
    }
    /deep/.el-checkbox__inner::after {
        border-color: #1890ff;
    }
}
.tab {
    position: relative;
    padding: 0 160px 0 50px;
    .reload-btn {
        position: absolute;
        width: 140px;
        height: 60px;
        background: #ECF2FF;
        right: 0;
        top: 0;
        .el-icon-refresh.move {
            animation: duangduang 1s linear infinite;
        }

    }
    .right {
        width: 280px;
        .item {
            width: 80px;
            text-align: center;
            line-height: 30px;
            border-radius: 2px;
            border: 1px solid #fff;
            &.in {
                border-color: #1890ff;
                color: #1890ff;
            }
        }
    }
}
.list {
    margin-top: 10px;
    background-color: #fff;
    position: relative;
    .group-btn {
        position: absolute;
        width: 36px;
        height: 157px;
        top: 10px;
        right: -18px;
        z-index: 3;
    }
    .center {
        position: static;
    }
    .item {
        height: 160px;
        padding: 20px 90px 20px 30px;
        background-color: #fff;
        transition: background-color 0.2s ease;
        position: relative;
        overflow: hidden;
        .user-tag {
            width: 68px;
            height: 22px;
            background: #FFFFFF;
            border: 1px solid #5AB0FF;
            border-radius: 4px;
            margin-left: 20px;
            .el-icon-sort {
                transform: rotate(90deg);
            }
        }
        .status_icon {
            position: absolute;
            width: 120px;
            // height: 20px;
            line-height: 20px;
            top: 10px;
            left: -41px;
            z-index: 3;
            transform: rotate(-45deg);
            font-size: 12px;
            color: #fff;
            background: linear-gradient(226deg, #A6C8E6 0%, #8BAFCF 100%);
            &.in {
                top: 20px;
                left: -32px;
                background: linear-gradient(226deg, #E6D1A6 0%, #CFAF8B 100%);
            }
        }
        &:hover {
            background-color: #f8f8f8;
            transition: background-color 0.2s ease;
        }
        .avatar {
            margin-right: 20px;
        }
        .userinfo {
            width: 660px;
            .label {
                width: 100px;
                font-size: 14px;
                color: #999;
            }
        }
        .job {
            width: 100px;
        }
        .mobile {
            min-width: 130px;
            .btn {
                width: 86px;
                height: 22px;
                border-radius: 2px;
                margin: 6px auto 0;
                &.msg {
                    border: #1890ff 1px solid;
                    color: #1890ff;
                }
                &.dis {
                    border: #c2c2c2 1px solid;
                    color: #c2c2c2;
                }
                &.tel {
                    border: #f8b33c 1px solid;
                    color: #f8b33c;
                }
                .icon {
                    width: 14px;
                    height: 14px;
                    margin-right: 5px;
                }
            }
        }
    }
}
</style>
