<template>
    <div class="telephone">
        <el-dialog v-model="dialogVisible" :lock-scroll="true" :before-close="close" width="460px" top="20vh">
            <template v-slot:title>
                <div class="flex_c">
                    <!-- <p class="el-icon-warning fs_24 mr_5"></p> -->
                    <p class="fs_18 bold">温馨提示</p>
                </div>
            </template>
            <div class="center content">
                <div class="padding_20">
                    <p class="fs_16 color_000">
                        联系人才需要花费 <span class="color">{{ data.pay_credit }}</span> CRC，是否继续？
                    </p>
                    <p class="fs_14 mt_10 color_000" style="color:#F56C6C">当前剩余 <span class="">{{ data.balance }}</span> CRC</p>

                </div>
            </div>
            <template v-slot:footer>
                <div class="flex_around">
                    <el-button type="info" size="medium" @click="close">取 消</el-button>
                    <el-button type="primary" :disabled="balance.disabled" size="medium" :loading="loading" @click="check">
                        {{ balance.btnText }}
                    </el-button>
                </div>
            </template>
        </el-dialog>
    </div>
</template>

<script>
// 电话沟通
export default {
	name: "telephone",
	data() {
		return {
			id: 0,
			data: { pay_credit: 0.0, balance: 0.0 },
			dialogVisible: false,
			index: 0,
			loading: false
		}
	},
	computed: {
		// CRC 余额判断
		balance() {
			if(this.data.balance-this.data.pay_credit < 0) {
				return {
					disabled: true,
					btnText: '剩余CRC不足'
				}
			}
			return {
				disabled: false,
				btnText: '继 续'
			}
		}
	},
	methods: {
		close() {
			this.dialogVisible = false
		},
		check() {
			this.loading = true
			this.$axios({
				url: `/ent/v3/talent/view_user/${this.id}`,
				method: "PUT",
				shake: true,
				hideLoading: true
			}).then(res => {
				setTimeout(() => {
					this.loading = false
				}, 900)
				if (res.code === 200) {
					this.$message({
						dangerouslyUseHTMLString: true,
						duration: 2000,
						customClass: "crc_change_tip",
						message: `<div class="flex_cen fs_18" ><p class="flex_1 crc-icon"></p><p class="FFAD20">成功扣除 ${this.data.pay_credit} CRC</p></div>`
					})
					if (this.$parent.list[this.index]) {
						this.$parent.list[this.index].realname = res.data.realname
						this.$parent.list[this.index].mobile = res.data.mobile
						this.$parent.list[this.index].view_status = 1
						this.$parent.list[this.index].log_status = 'companyView'
						this.$parent.list[this.index].log_status_txt = '已查看'
					}
				} else {
					this.$message.error("支付失败")
				}
			})

			this.close()
		}
	}
}
</script>

<style lang="less" scoped>
.el-icon-warning {
    color: #ff9900;
}
/deep/.el-dialog__header {
    padding: 20px;
}
/deep/.el-dialog__body {
    padding: 0;
}
.content {
    border-top: 1px solid #ddd;
    height: 168px;
    padding: 30px;
}
/deep/.el-button {
    width: 162px;
    height: 42px;
    font-size: 16px;
}
</style>
