<template>
    <div class="telephone">
        <el-dialog v-model="dialogVisible" :lock-scroll="true" top="10vh" width="1020px">
            <template v-slot:title>
                <div class="flex_c">
                    <p class="fs_18 bold">查看结果</p>
                </div>
            </template>
            <div class="content">
                <div class="flex_cen fs_16 color_333">
                  本次成功查看{{ list.length }}人，共花费<span class="fs_18 bold color mt_3">{{cost_crc.toFixed(2)}}CRC</span>
                </div>
                <el-row class="mt_20" :gutter="20">
                    <el-col :span="12">
                        <el-table :data="list10" border height="550px">
                            <el-table-column prop="index" label="序号"></el-table-column>
                            <el-table-column prop="realname" label="姓名"></el-table-column>
                            <el-table-column prop="mobile" label="手机号"></el-table-column>
                        </el-table>
                    </el-col>
                    <el-col :span="12">
                        <el-table :data="list20" border height="550px">
                            <el-table-column prop="index" label="序号"></el-table-column>
                            <el-table-column prop="realname" label="姓名"></el-table-column>
                            <el-table-column prop="mobile" label="手机号"></el-table-column>
                        </el-table>
                    </el-col>
                </el-row>
                <div class="fs_14 padding_20 center price_color">本批量查看结果仅显示一次，如有需要请复制并保存！</div>
                <div class="flex_cen">
                    <el-button type="info" size="medium" @click="dialogVisible=false">关闭</el-button>
                    <el-button type="primary" size="medium" @click="copyJSON">一键复制</el-button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
// 批量查看
export default {
	name: "groupViews",
	data() {
		return {
			dialogVisible: false,
			list: [],
			list10: [],
			list20: [],
			cost_crc: 0
		}
	},

	methods: {
		getList(data) {
			this.$axios({
				url: `/ent/v3/talent/view_user/batch`,
				method: 'PUT',
				shake: true,
				data
			}).then(res => {
				if (res.code === 200) {
					let { talent, cost_crc } = res.data
					this.cost_crc = cost_crc
					talent.forEach((item, index)=>{
						item.index = index+1
					})
					this.list = talent
					this.list10 = talent.filter((item, index)=>{
						if(index < 10) {
							return item
						}
					})
					this.list20 = talent.filter((item, index)=>{
						if(index > 9) {
							return item
						}
					})
					this.dialogVisible = true
					// 批量查看完成，数据回显
					this.$parent.list.forEach(item=>{
						this.list.forEach(s_item=>{
							if(item.id == s_item.user_id) {
								item.realname = s_item.realname
								item.mobile = s_item.mobile
								item.view_status = 1
								item.log_status = 'companyView'
								item.log_status_txt = '已查看'
							}
						})
					})
				}
			})
		},
		copyJSON() {
			var copyText = ''
			this.list.forEach(item=>{
				copyText += `${item.realname}：${item.mobile}\n`
			})
			let target = document.createElement('textarea')
			target.value = copyText
			document.body.appendChild(target)
			target.select() // 选中textarea
			try {
				document.execCommand('Copy') // 执行浏览器复制命令
				this.$message('已复制到粘贴板')
			} catch {
				console.log('复制失败')
			}

		}
	}
}
</script>

<style lang="less" scoped>
.color_5AB0FF {
    color: #5ab0ff;
}
/deep/.el-dialog__header {
    padding: 20px;
}
/deep/.el-dialog__body {
    padding: 0;
}
.content {
    border-top: 1px solid #ddd;
    // height: 736px;
    padding: 20px 25px;
    /deep/.el-table thead tr th {
        height: 50px;
        background: #f8f8f8 !important;
        border: none;
        /deep/.cell {
            font-weight: bold;
        }
    }
    /deep/.el-table td,
    .el-table th.is-leaf,
    /deep/.el-table--border,
    .el-table--group {
        border-color: #f8f8f8;
    }
    /deep/.el-table__header,
    /deep/.el-table--border::after,
    .el-table--group::after,
    .el-table::before {
        background: #f8f8f8;
    }
    /deep/.cell {
        font-size: 14px;
        color: #000;
        text-align: center;
    }
    /deep/.el-table--enable-row-transition .el-table__body td {
        height: 50px;
    }

    /deep/.form-item {
        .el-input__inner {
            border: none;
            height: 50px;
            background: #f9f9f9;
            font-size: 14px;
            color: #000;
        }
    }
    .el-button {
        width: 162px;
        height: 42px;
        font-size: 16px;
        position: relative;
        z-index: 9;
        margin: 0 10px 0;
    }
}
</style>
