var education = [
	{
		value: 0,
		label: "不限"
	},
	{
		value: 1,
		label: "小学及以上"
	},
	{
		value: 2,
		label: "初中及以上"
	},
	{
		value: 3,
		label: "技校/中专及以上"
	},
	{
		value: 4,
		label: "高中及以上"
	},
	{
		value: 5,
		label: "大专及以上"
	},
	{
		value: 6,
		label: "本科及以上"
	},
	{
		value: 7,
		label: "硕士及以上"
	},
	{
		value: 8,
		label: "博士及以上"
	}
]
var education_select = [
	{
		value: 0,
		label: "不限"
	},
	{
		value: 1,
		label: "小学"
	},
	{
		value: 2,
		label: "初中"
	},
	{
		value: 3,
		label: "技校/中专"
	},
	{
		value: 4,
		label: "高中"
	},
	{
		value: 5,
		label: "大专"
	},
	{
		value: 6,
		label: "本科"
	},
	{
		value: 7,
		label: "硕士"
	},
	{
		value: 8,
		label: "博士"
	}
]
var work_exp = [
	{
		value: 0,
		label: "不限"
	},
	{
		value: 1,
		label: "1年以下"
	},
	{
		value: 2,
		label: "1-3年"
	},
	{
		value: 3,
		label: "3-6年"
	},
	{
		value: 4,
		label: "6-10年"
	},
	{
		value: 5,
		label: "10年以上"
	}
]
var gender = [
	{
		value: 0,
		label: "不限"
	},
	{
		value: 1,
		label: "男"
	},
	{
		value: 2,
		label: "女"
	}
]
var getAge = function() {
	let i = 0,
		num = 0
	let ageList = []
	for (i = 0; i <= 49; i++) {
		num = i + 16
		ageList[i] = {
			value: num,
			label: num + "岁",
			disabled: false
		}
	}
	// if(!max) {
	ageList = [
		{
			value: 0,
			label: "不限",
			disabled: false
		}
	].concat(ageList)
	// }
	return ageList
}
var circle = [
	// {
	// 	value: "every",
	// 	label: "次结"
	// },
	{
		value: "day",
		label: "日结"
	},
	{
		value: "week",
		label: "周结"
	},
	{
		value: "month",
		label: "月结"
	}
]
function getEducationText(str) {
	return getText(str, education)
}
function getWorkExpText(str) {
	return getText(str, work_exp)
}
function getGenderText(str) {
	return getText(str, gender)
}
function getText(str, type) {
	for (var i in type) {
		var item = type[i]
		if (item.value == str) {
			return item.label
		}
	}
}

function getGender(data) {
	return getFilter(data, gender)
}
function getEducation(data) {
	return getFilter(data, education)
}
function getEducationSelect(data) {
	return getFilter(data, education_select)
}
function getExp(data) {
	return getFilter(data, work_exp)
}
function getFilter(data, type) {
	var list = [],
		i = 0
	data.forEach((element, index) => {
		for (i in type) {
			var item = type[i]
			if (item.value == element) {
				list[index] = item.label
				continue
			}
		}
	})
	return list
}

//转换二维数组为一维
function two2one(data, str) {
	var list = []
	for (var index in data) {
		list[str + "[" + index + "]"] = data[index]
	}
	return list
}

export {
	education,
	getEducation,
	getEducationSelect,
	gender,
	getGender,
	work_exp,
	circle,
	education_select,
	getExp,
	getAge,
	two2one,
	getEducationText,
	getWorkExpText,
	getGenderText
}
