<template>
    <div class="telephone">
        <el-dialog v-model="dialogVisible" :lock-scroll="true" :before-close="close" width="460px" top="20vh">
            <template v-slot:title>
                <div class="flex_c">
                    <!-- <p class="el-icon-warning fs_24 mr_5"></p> -->
                    <p class="fs_18 bold">温馨提示</p>
                </div>
            </template>
            <div class="center content">
                <div class="padding_20">
                    <p class="fs_16 color_000">
                        批量查看前 <span class="color">{{ count }}</span> 人，共需要花费 <span class="color">{{ (configure.check_info*count).toFixed(2) }}</span> CRC，是否继续？
                    </p>
                    <p class="fs_14 mt_10 color_000" style="color:#F56C6C">当前剩余 <span class="">{{ companyInfo.balance }}</span> CRC</p>
                </div>
            </div>
            <template v-slot:footer>
                <div class="flex_around">
                    <el-button type="info" size="medium" @click="close">取 消</el-button>
                    <el-button type="primary" :disabled="balance.disabled" size="medium" :loading="loading" @click="check">
                        {{ balance.btnText }}
                    </el-button>
                </div>
            </template>
        </el-dialog>
    </div>
</template>

<script>
// 批量电话沟通
import { mapGetters } from "vuex"
import { l_getItem } from '@/common/cache'

export default {
	name: "telephone",
	data() {
		return {
			id: 0,
			count: 20,
			data: { pay_credit: 0.0, balance: 0.0 },
			dialogVisible: false,
			index: 0,
			loading: false
		}
	},
	computed: {
		...mapGetters({
			companyInfo: "getCompanyInfo",
			configure: "getConfigure"
		}),
		// CRC 余额判断
		balance() {
			let companyInfo = l_getItem('COMPANY')
			if(companyInfo.balance - this.configure?.check_info*this.count <= 0) {
				return {
					disabled: true,
					btnText: '剩余CRC不足'
				}
			}
			return {
				disabled: false,
				btnText: '继 续'
			}
		}
	},
	methods: {
		close() {
			this.dialogVisible = false
		},
		check() {
			this.$emit('callback')

			this.close()
		}
	}
}
</script>

<style lang="less" scoped>
.el-icon-warning {
    color: #ff9900;
}
/deep/.el-dialog__header {
    padding: 20px;
}
/deep/.el-dialog__body {
    padding: 0;
}
.content {
    border-top: 1px solid #ddd;
    height: 168px;
    padding: 30px 0px;
}
/deep/.el-button {
    width: 162px;
    height: 42px;
    font-size: 16px;
}
</style>
