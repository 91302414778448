<template>
    <div class="">
        <el-dialog v-model="dialogVisible" :lock-scroll="true" custom-class="Invited" width="660px" top="10vh">
            <template v-slot:title>
                <div class="flex_c bgf">
                    <p class="fs_18 bold">面试邀请详情</p>
                </div>
            </template>
            <el-form ref="form" :model="form">
                <div class="content">
                    <div class="tabel">
                        <p class="flex row">
                            <span class="label">面试人员</span>
                            <el-input placeholder="请填写面试人员姓名" class="value flex_1 flex_c" v-model.trim="form.realname" readonly></el-input>
                        </p>
                        <p class="flex row">
                            <span class="label">公司名称</span>
                            <el-input placeholder="请填写公司名称" class="value flex_1 flex_c" v-model.trim="form.company_name" readonly></el-input>
                        </p>
                        <p class="flex row">
                            <span class="label">面试职位</span>
                            <el-input class="value flex_1 flex_c color" v-model.trim="form.job_name" readonly></el-input>
                        </p>
                        <p class="flex row">
                            <span class="label">面试时间</span>
                            <el-input placeholder="请填写面试时间" class="value flex_1 flex_c color" v-model.trim="form.pick_time" readonly></el-input>
                        </p>
                        <p class="flex row">
                            <span class="label">面试地点</span>
                            <el-input placeholder="请填写面试地点" class="value flex_1 flex_c" v-model.trim="form.address" readonly></el-input>
                        </p>
                        <p class="flex row">
                            <span class="label">联系人员</span>
                            <el-input placeholder="请填写联系人员姓名" class="value flex_1 flex_c" v-model.trim="form.contacts_name" readonly></el-input>
                        </p>
                        <p class="flex row">
                            <span class="label">联系方式</span>
                            <el-input placeholder="请填写联系方式" class="value flex_1 flex_c" type="tel" v-model.trim="form.contacts_tel" readonly></el-input>
                        </p>
                        <p class="flex row">
                            <span class="label">备注</span>
                            <el-input placeholder=" " class="value flex_1 flex_c" v-model.trim="form.remark" readonly></el-input>
                        </p>
                    </div>
                </div>
                <div class="footer bgf flex_cen">
                    <img src="../../../assets/imgs/invited-bg.png" class="invited-bg" alt="" />
                    <div class="flex_around">
                        <el-button type="primary" size="medium" @click="dialogVisible = false">关闭</el-button>
                    </div>
                </div>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
// 面试邀请详情
export default {
	name: "Invited",
	data() {
		return {
			dialogVisible: false,
			form: {
				job_name: null,
				realname: null,
				company_name: null,
				address: null,
				pick_time: null,
				contacts_name: null,
				contacts_tel: null,
				remark: null
			}
		}
	}
}
</script>

<style lang="less" scoped>
/deep/.el-dialog__header {
    padding: 20px;
}
/deep/.el-dialog__body {
    padding: 0;
}
/deep/.Invited {
    background: transparent;
    padding: 0 30px;
    box-shadow: none;
    .el-dialog__header {
        background: #fff;
        border-radius: 2px 2px 0 0;
        .el-dialog__headerbtn {
            right: 50px;
        }
    }
    .Invited-tabel {
        position: relative;
    }
    .content {
        border-top: 1px solid #ddd;
        background: #fff;
        padding: 20px 42px 42px;
        position: relative;
        z-index: 3;
        .rili-icon {
            width: 14px;
            height: 14px;
            margin-right: 8px;
        }
        .tabel {
            margin-top: 10px;
            border: 1px solid #f1f1f1;
            border-bottom: none;
            .row {
                border-bottom: 1px solid #f1f1f1;
                .label {
                    width: 94px;
                    height: 50px;
                    line-height: 50px;
                    text-align: center;
                    border-right: 1px solid #f1f1f1;
                }
                .datetime {
                    line-height: 50px;
                }
                .el-input__inner {
                    border: none;
                    color: #000;
                }
                .value {
                    height: 50px;
                    padding: 0 5px;
                }
                .select-trigger {
                    width: 100%;
                }
                .el-input--prefix .el-input__inner {
                    padding-left: 15px;
                }
                .el-input__prefix {
                    display: none;
                }
                .color {
                    .el-input__inner {
                        color: #1890ff;
                    }
                }
            }
        }
    }
    .footer {
        position: relative;
        height: 136px;
        .invited-bg {
            position: absolute;
            width: 660px;
            height: 200px;
            left: -30px;
            bottom: 0px;
            z-index: 0;
        }
        .el-button {
            width: 200px;
            height: 50px;
            font-size: 18px;
            position: relative;
            z-index: 9;
            margin: 0 20px;
            &.cancel {
                background: #fff;
                color: #1890ff;
                border-color: #fff;
            }
        }
    }
}
</style>
